


















import Vue from 'vue'

interface Data {
    select?: HTMLSelectElement
    isFocused: boolean
    showSmallUI: boolean
    isDisabled: boolean
}

export default Vue.extend({
    name: 'MaterialSelect',
    props: {
        id: { type: String, required: false },
        name: { type: String, required: false },
        label: {type: String, required: true },
        value: String as () => string | null,
        items: Array,
        enabled: { type: Boolean, required: false, default: true },
        note: {type: String, required: false },
        errors: { type: Array, required: false }
    },
    data(): Data {
        return {
            isFocused: false,
            showSmallUI: false,
            isDisabled: false
        }
    },
    methods: {
        selectedValue(): string {
            if (this.select == undefined) {
                return '-1'
            }
            return this.select.value == undefined ? '-1' : this.select.value
        },
        getShowSmallUI(): boolean {
            return this.isFocused || this.selectedValue() != '-1';
        },
        onChange() {
            const selectedValue = this.selectedValue()
            // This triggers v-model
            this.$emit('input', selectedValue); 
            // This emits the ordinary change event
            this.$emit('change', selectedValue); 
            this.showSmallUI = this.getShowSmallUI();
        }
    },
    computed: {
        isInvalid(): boolean {
            return this.errors != null && this.errors.length > 0;
        }
    },
    watch: {
        isFocused() {
            this.showSmallUI = this.getShowSmallUI()
        },
        isDisabled(): boolean {
            return !this.enabled
        }
    },
    mounted() {
        this.select = this.$refs.select as HTMLSelectElement
        this.select.value = this.value || '-1' 
        this.showSmallUI = this.getShowSmallUI()
    },
    updated() {
        this.select = this.$refs.select as HTMLSelectElement
        this.select.value = this.value || '-1'
        this.showSmallUI = this.getShowSmallUI()
    }
});
